import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./assets/styles/_index.scss"
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PlainContent = (props) => {
  return (
    <section className="static-detail-desc-wrapper plain-content section-p">
      <Container>
        <Row className={props?.layout === "center_align" ? "d-flex justify-content-center" : ""}>
          <Col xl={9}>
            <div className="static-detail-desc">
              <ContentModule Content={props.content?.data?.content} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PlainContent
